@import "./variables.styl";

#content {
  .profile {
    margin-top: 50px;
    text-align: center;
  }

  .story-footer {
    text-align: center;
    display: block;
    padding: 50px 0;
  }

  .container.story {
    margin-top: 50px;
    background: white;

    &.compose {
      box-shadow: 0 0 16px 2px rgba(0,0,0,0.20);
    }
  }

  .row {
    position: relative;
    margin: 0;

    &.center {
      text-align: center;
    }

    &.last {
      height: 100px;
      margin-top: 50px;

      .add-tool {
        top: 0;
      }
    }

    .time {
      text-align: right;
      padding: 5px 10px 30px;

      font-weight: 300;
      font-size: 13pt;
    }

    &.draft {
      opacity: 0.7;
    }

    .preview {
      cursor: pointer;
      position: relative;
      color: $sidestory-white;
      text-shadow: 0 0 2px #000000;
      background: $sidestory-light-gery;

      .img {
        width: 100%;
        padding-top: 33%;
        box-sizing: border-box;
        position: relative;
        background-color: $sidestory-light-gery;
        background-size: cover;
        border: 1px solid $sidestory-light-gery;
      }

      .title {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
      }
    }

    .empty-profile {
      text-align: center;
      padding-top: 50px;
      padding-bottom: 50px;

      h1 {
        margin-bottom: 25px;
      }

      span {
        display: inline-block;
        margin: 35px;
      }
    }

    .add-tool {
      cursor: text;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 0;
      z-index: 998;

      .btn {
        display: none;
      }

      input[type="file"] {
        opacity: 0;
        height: 0;
        width: 0;
        margin-left: -10000000;
        /*
         * Margin fixes a bug where clicking near the style tools
         * was opening the file viewer.
         */
        padding: 0;
      }
    }

    .style-tool {
      opacity: 0;
      position: absolute;
      top: -12.5px;
      z-index: 999;
      line-height: 0;

      .btn {
        display: none;
      }
    }

    .add-tool,
    .style-tool {
      transition: opacity 0.2s;
    }

    .remove-media-btn {
      display: none;
      position: absolute;
      right: 0;
      z-index: 999;
    }

    textarea.add-text:focus ~ .add-tool,
    textarea.add-text:focus ~ .style-tool {
      opacity: 1;

      .btn {
        display: inline-block;
      }
    }

    textarea.add-text.typing ~ .add-tool,
    textarea.add-text.typing ~ .style-tool {
      opacity: 0 !important;
    }

    &.last,
    &.show-tools:hover {
      .add-tool,
      .remove-media-btn {
        display: block;
        opacity: 1;

        .btn {
          display: inline-block;
        }
      }
    }

    img,
    video {
      margin: 0;
    }
  }

  p.h1,
  p.h2,
  p.h3,
  p.p,
  p.quote {
    margin: 0 !important;
    padding: 10px 20px !important;
  }

  p.placeholder {
    color: #c0c0c0;
  }

  p {
    &.p {
      font-size: 20px;
      font-weight: 400;
    }

    &.quote {
      font-size: 36px;
      text-align: center;
      font-weight: 300;
      font-style: italic;
    }

    &.h1 {
      font-size: 44px;
      font-weight: 600;
    }

    &.h2 {
      font-size: 44px;
      font-weight: 300;
    }

    &.h3 {
      font-size: 88px !important;
      font-weight: 300!important;
    }
  }

  .row.media + .row.text,
  .row.text + .row.media {
    padding-top: 10px;
  }

  .row.media + .row.media {
    padding-top: 0;
  }

  .row.text + .row.media .add-tool {
    top: -2.5px;
  }

  .row.media + .row.media .add-tool {
    height: 50px;
    top: -25px;
    padding: 12.5px;
  }

  .row.text .add-tool {
    top: -12.5px;
  }
}

textarea.add-text {
  transition: 0.1s background;
  display: block;
  resize: none;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 10px 20px;

  border-color: transparent;

  background: transparent;

  &:hover {
    background: lighten($sidestory-light-gery, 50%);
  }

  &:focus {
    background: $sidestory-light-gery;
  }

  &.text {
    font-size: 24px;
    font-weight: 400;
    color: #666666;
  }

  &.header {
    font-size: 42px;
    font-weight: 700;
  }

  &.p {
    font-size: 20px;
    font-weight: 400;
  }

  &.quote {
    font-size: 36px;
    text-align: center;
    font-weight: 300;
    font-style: italic;
  }

  &.h1 {
    font-size: 44px;
    font-weight: 600;
  }

  &.h2 {
    font-size: 44px;
    font-weight: 300;
  }

  &.h3 {
    font-size: 88px !important;
    font-weight: 300 !important;
  }
}

.share-preview {
  margin-bottom: 20px;

  img {
    border: 1px solid $sidestory-light-gery;
    width: 100%;
  }
}

.upload-modal {
  text-align: center;

  .upload-file-spinner {
    height: 80px;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 750px) {
  #content {
    .container.story {
      box-shadow: none;
    //   border-bottom: 1px solid $sidestory-light-gery;
    }
  }
}


.page-loading {
    text-align: center;
    padding: 15px;

    &.show {
        img{
            visibility: visible;
        }
    }

    img {
        visibility: hidden;
        height: 90px;
    }
}
