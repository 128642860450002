@import "./variables.styl";

@media only screen and (max-width: $desktop-min-width) {
    .desktop {
        display: none;
    }
}

@media only screen and (min-width: $mobile-max-width) {
    .mobile {
        display: none;
    }
}
