#content.page-account,
#content.page-signup,
.page-account,
.page-signup {
  .picture {
    height: 250px;

    .crop_avatar {
      cursor: pointer;
      position: relative;
      border: none;
      overflow: visible;

      img#avatar {
        border-radius: 50%;
      }

      img.add-picture {
        position: absolute;
        bottom: -7px;
        left: 30px;
        height: 15px;
      }
    }

    &.hide {
      display: none;
    }

    #profile-croppie-input {
      opacity: 0;
      height: 0;
      width: 0;
      padding: 0;
    }

    .profile-croppie-container {
      margin-top: 35px;
      height: 150px;

      .actions {
        text-align: center;
      }
    }
  }
}
