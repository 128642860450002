h1.page-title {
    font-size: 36px;
    font-weight: 700;
}

h1.page-subtitle {
    font-size: 16px;
    font-weight: 300;
    font-style: oblique;
}
