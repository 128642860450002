@import "../node_modules/stylus-base/index.styl";
@import "../node_modules/croppie/croppie.css";

@import "./message.styl";
@import "./join-with-phone.styl";

@import "./story.styl";
@import "./media-player.styl";
@import "./form.styl";
@import "./modal.styl";
@import "./nav.styl";
@import "./404.styl";
@import "./login.styl";

@import "../app/pages/account"

@import "./fonts.styl";
@import "./theme.styl";
@import "./unsupported.styl";

.content-body {
    padding-top: 60px;
}

.inline-block {
    display: inline-block;
}

[x-route] {
    cursor: pointer;
}

@import "./responsive.styl";
