@import "../css/toolkit-minimal.css";
@import "../css/application-minimal.css";

/* Message */

html, body {
  margin:0px;
  padding:0px;
  border:none;
  word-wrap: break-word;
}

body {
  font-family:"futura-pt", Helvetica, Arial, sans-serif;
  color:#000000;
}

#content {
    .container {
        max-width:740px;
        min-width:200px;
        margin:0px auto;
        padding: 0;
    }

    .row {
        clear: both;
    }

    .top {
        float:left;
        clear:both;
        margin-bottom:20px;
    }

    .crop_avatar {
        width:75px;
        height:75px;
        border-radius:50%;
        overflow:hidden;
        margin:20px;
        margin-right:0px;
        float:left;
        border: 1px solid #F2F2F2 ;
    }

    .inline-block .crop_avatar {
        float: none;
        margin: 0;
    }

    .inline-block {
        display: inline-block;
    }

    #avatar {
        text-decoration:none;
        -ms-interpolation-mode: bicubic;
        width: 75px;
        max-width: 100%;
        float: none;
        display: block;
        margin: 0px auto;
    }

    .context {
        margin: 20px;
        float: left;
        clear: right;
        color: #666666 ;
        font-size: 20px;
        font-weight: 400;
        color: #B3B3B3 ;
    }

    .name {
        margin: 0px;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #000 ;
    }

    .date {
        margin:0px;
        font-size:20px;
    }

    /* content */

    p.quote {
        font-size:36px;
        text-align: center;
        font-weight:300;
        font-style: italic;
        margin: 20px;
    }

    p {
        font-size:20px;
        font-weight:400;
        margin: 20px;
    }

    p.h2 {
        font-size:44px;
        font-weight:300;
        margin: 20px;
    }

    p.h1 {
        font-size:44px;
        font-weight:600;
        margin: 20px;
    }

    p.h3 {
        font-size:88px !important;
        font-weight:300!important;
        margin: 20px !important;
    }

    p.text {
        font-size:24px;
        font-weight:400;
        color:#666666;
        margin:30px;
    }

    p.header {
        font-size:42px;
        font-weight:700;
        margin:30px;
    }

    img.photo {
        display:block;
        width:100%;
    }

    img.emoji {
        height: 1em;
        width: 1em;
        margin: 0 .05em 0 .1em;
        vertical-align: -0.1em;
    }

    div.loading {
        margin: 100px auto;
        width: 66px;
        height: 66px;
    }

    .video_container {
        width:100%;
        position:relative;
    }

    video.video {
        width: 100%;
        height: auto;
        display: block;
    }

    .video_overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: url("https://dtwr9uud83x48.cloudfront.net/img/video_overlay.png") no-repeat;
        background-position: 50% 50%;
    }

    /* prevent video auto fullscreen */

    .IIV::-webkit-media-controls-play-button,
    .IIV::-webkit-media-controls-start-playback-button {
        opacity: 0;
        pointer-events: none;
        width: 5px;
    }

    /* footer */

    .app-footer {
        min-height: 200px;
    }

    .app-footer .container-fluid {
        margin-top: 60px;
    }

    /* END - Message */

    .inline-block .crop_avatar {
        float: none;
        margin: 0;
    }

    .inline-block {
        display: inline-block;
    }

    .no-m-t {
        margin-top: 0 !important;
    }
}



@import "./join-with-phone.styl";
@import "./modal.styl";
@import "./nav.styl";
@import "./form.styl";
