@import "./variables.styl";

.page-404 {
  min-height: inherit;

  .box-404 {
    position: relative;
    width: 400px;
    margin: 0 auto;
  }
}
