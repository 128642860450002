#unsupported {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: #000;
    color: #fff;
    text-align: center;

    .logo {

    }

    .title {
        padding: 75px;
        font-weight: 300;
        font-style: oblique;

        font-size: 36px;
    }

    .message {
        padding: 5px;
        font-weight: 400;

        font-size: 18px;
    }
}
