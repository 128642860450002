@import "./variables.styl";

.move-for-side-nav {
  position: relative;
  left: 0;
  right: 0;
  transition: left 0.2s ease 0s, right 0.2s ease 0s;

  &.nav-open {
    left: -200;
    right: 200;
  }
}

.title-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 9999;
  background: rgba($sidestory-white, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: $sidestory-black;

  .darklogo {
    display: none;
  }

  transition: left 0.2s ease 0s, right 0.2s ease 0s, height 0.2s ease 0s, padding 0.2s ease 0s, background 0.2s ease 0s;

  &.fixed {
    position: fixed;
  }

  &.clear {
    background: transparent;
    color: $sidestory-white;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;

    .btn .icon-bar {
      background: $sidestory-white;
    }
  }

  &.navdark {

    color: $sidestory-black;

    .lightlogo {
      display: none;
    }

    .darklogo {
      display: initial;
    }

  }

  &.scroll-nav {
    height: 50px;

    & > .left-group,
    & > .right-group {
      padding: 7.5px 20px;
    }

    .title-logo {
      display: inline-block;
      height: 35px;
      padding: 10px 0;

      & > img {
        height: 15px;
        margin: 0;
        padding: 0;
      }
    }
  }

  &.dark {
    background: rgba(0, 0, 0, 0.85);
    color: $sidestory-white;
    height: 50px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    .lightlogo {
      display: initial;
    }

    .darklogo {
      display: none;
    }

    & > .left-group,
    & > .right-group {
      padding: 7.5px 20px;
    }

    .title-logo {
      display: inline-block;
      height: 35px;
      padding: 10px 0;

      & > img {
        height: 15px;
        margin: 0;
        padding: 0;
      }
    }

    .btn .icon-bar {
      background: $sidestory-white;
    }
  }

  &.border-bottom {
    border-bottom: 1px solid $sidestory-black;
  }

  & > .left-group,
  & > .right-group {
    transition: padding 0.2s;
    transition-delay: 0s;
    position: absolute;
    display: inline-block;
    padding: 12.5px 20px;
  }

  & > .left-group {
    left: 0;
  }

  & > .right-group {
    right: 0;
  }

  .profile {
    .crop_avatar {
      margin-top: -5px;
      margin-right: 10px;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background-size: cover;
      background-position: 50%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .open-nav {
    margin-left: 20px;
  }

  a.btn.link {
    color: inherit;
    padding-left: 5px;
    padding-right: 20px;
  }

  .btn .icon-bar {
    display: block;
    width: 22px;
    height: 3px;
    border-radius: 1px;
    background: $sidestory-black;
    margin-top: 3px;
  }

  .btn .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .title-logo {
    display: inline-block;
    height: 35px;
    padding: 5px 0;

    transition: height 0.2s, padding 0.2s;
    transition-delay: 0s;

    & > img {
      transition: height 0.2s, padding 0.2s;
      transition-delay: 0s;
      height: 25px;
      margin: 0;
      padding: 0;
    }
  }
}

.side-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  overflow: scroll;
  background: #f8f8f8;
  padding: 30px;
  font-size: 12pt;

  // box-shadow: inset 12px 0px 15px -10px rgba(0,0,0,0.75);

  &.nav-close {
    display: none;
  }

  &.dark {
    background: $sidestory-black;
    color: $sidestory-white;

    a {
      color: darken($sidestory-white, 20%);

      &:hover {
        color: darken($sidestory-white, 40%);
      }
    }
  }

  a {
    color: #777;
    font-weight: 200;

    &:hover {
      color: #000;
    }

    &.green {
      color: $sidestory-green;

      &:hover {
        color: darken($sidestory-green, 10%);
      }
    }

    &.red {
      color: $sidestory-red;

      &:hover {
        color: darken($sidestory-red, 10%);
      }
    }
  }

  li {
    margin-top: 10px;
  }
}

.body-content {
  z-index: 1;
  position: relative;
  top: 0;
  min-height: 100%;
  min-height: calc(100% - (150px));
  padding-top: 60px;

  &.no-fix {
    padding-top: 0;
  }

  background: $sidestory-white;
}

.footer {
  z-index: 0;
  position: relative;
  height: 150px;
}

// hid tablet and desktop
@media only screen and (max-width: 500px) {
  .title-nav {
    .desktop:not(.phone),
    .tablet:not(.phone) {
      display: none;
    }
  }
}

// hid phone and desktop
@media only screen and (min-width: 499px) and (max-width: 870px) {
  .title-nav {
    .desktop:not(.tablet),
    .phone:not(.tablet) {
      display: none;
    }
  }
}

// hid phone and tablet
@media only screen and (min-width: 869px) {
  .title-nav {
    .phone:not(.desktop),
    .tablet:not(.desktop) {
      display: none;
    }
  }
}
