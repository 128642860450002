.video-player-container {
  position: relative;
  width: 100%;
  padding-top: 75%; //3:4
  padding-top: 56.25%; //9:16
  box-sizing: border-box;
  position: relative;
  background: black;
  color: white;

  video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto !important;
  }

  &.hide-controls {
    .media-controls,
    .media-overlay {
      display: none;
    }
  }

  .media-controls,
  .media-overlay {
    transition: opacity 0.2s ease 0.6s;
  }

  .media-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  &.playing {
    .media-controls,
    .media-overlay {
      opacity: 0;
    }

    &.playing:hover {
      .media-controls,
      .media-overlay {
        transition: opacity 0.2s ease 0s;
        opacity: 1;
      }
    }
  }

  .media-controls {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 0;

    background: rgba(0, 0, 0, 0.2);
  }

  .media-time-line {
    position: relative;
    height: 2px;
    background: #585858;

    transition: height 0.2s ease 0.6s;

    &:hover {
      transition: height 0.2s ease 0s;
      height: 8px;
    }

    .buffered,
    .played {
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
    }

    .buffered {
      background: #9E9E9E;
    }

    .played {
      background: #FFFFFF;
    }
  }

  .media-time {
    position: absolute;
    padding: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 15px;
    line-height: 46px;
    font-weight: 200;
  }

  .play-btn {
    position: relative;
    display: inline-block;
    padding: 15px;
    cursor: pointer;

    img {
      vertical-align: middle;
      height: 20px;
      display: none;
    }

    &.playing {
      img.pause {
        display: inline-block;
      }
    }

    &.paused {
      img.play {
        display: inline-block;
      }
    }
  }

  .volume-btn {
    line-height: 0;
    position: relative;
    display: inline-block;
    padding: 15px 15px 15px 0;
    cursor: pointer;

    img {
      vertical-align: middle;
      height: 20px;
      display: none;
    }

    &.mute {
      img.mute {
        display: inline-block;
      }
    }

    &.volume-0 {
      img.volume-0 {
        display: inline-block;
      }
    }

    &.volume-1 {
      img.volume-1 {
        display: inline-block;
      }
    }

    &.volume-2 {
      img.volume-2 {
        display: inline-block;
      }
    }

    &.volume-3 {
      img.volume-3 {
        display: inline-block;
      }
    }
  }

  .play-btn + .volume-btn {
    padding-left: 0;
  }

  .volume-controls {
    line-height: 0;
    position: relative;
    display: inline-block;

    &:hover {
      .volume-slider-container {
        transition: opacity 0.2s ease 0s;
        opacity: 1;
      }
    }

    .volume-slider-container {
      position: relative;
      display: inline-block;
      height: 50px;
      padding-top: 23px;
      vertical-align: middle;
      transition: opacity 0.2s ease 0.6s;
      opacity: 0;

      .volume-slider {
        position: relative;
        height: 3px;
        width: 50px;
        background: #9E9E9E;

        .volume-slider-value {
          position: relative;
          height: 3px;
          width: 0;

          background: #FFFFFF;
        }

        .volume-ball {
          position: relative;
          height: 9;
          width: 9;
          top: -6px;
          left: -4.5px;
          border-radius: 4.5px;
          background: #FFFFFF;
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .video-player-container {
    position: relative;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: none;
    padding: 0;

    video {
      position: static;
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: none;
    }
  }
}
