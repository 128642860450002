@import "./variables.styl";

a.btn,
button,
div.btn,
div.input-box,
div.select-box,
input,
label.btn,
select,
textarea {
  box-sizing: border-box !important;
  margin: 0;
  padding: $input-padding;
  border-radius: $input-border-radius;

  font-size: $input-font-size;
  font-style: $input-font-style;
  font-family: $input-font-family;
  font-weight: $input-font-weight;

  background: $input-background;
  outline: $input-outline;
  border: 1px solid $input-border;
  color: $input-color;

  &.submit {
    background: $input-background-submit;
    outline: $input-outline-submit;
    border: 1px solid $input-border-submit;
    color: $input-color-submit;
  }

  &.info {
    background: $input-background-info;
    outline: $input-outline-info;
    border: 1px solid $input-border-info;
    color: $input-color-info;
  }

  &.warn {
    background: $input-background-warn;
    outline: $input-outline-warn;
    border: 1px solid $input-border-warn;
    color: $input-color-warn;
  }

  &.danger {
    background: $input-background-danger;
    outline: $input-outline-danger;
    border: 1px solid $input-border-danger;
    color: $input-color-danger;
  }

  &.disabled,
  &:disabled {
    background: $input-background-disabled;
    outline: $input-outline-disabled;
    border: 1px solid $input-border-disabled;
    color: $input-color-disabled;
  }
}

div.input-box {
  display: inline-block;
  padding: 0;
  margin: 0;

  input {
    height: 23px;
    background: none;
    outline: none;
    border: none;
  }
}

div.input-box,
input {
  height: 25px;

  &[type="button"],
  &[type="checkbox"],
  &[type="color"],
  &[type="radio"],
  &[type="range"],
  &[type="reset"],
  &[type="submit"] {
    cursor: pointer;
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    min-width: 50px;
  }

  &[type="checkbox"],
  &[type="file"],
  &[type="radio"],
  &[type="range"] {
    height: initial;
  }

  &[type="file"] {
    border: initial;
  }

  &.full {
    width: 100%;
  }

  &.fill-blanks {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    font-size: 16px;
  }

  &.big {
    height: 35px;
    min-width: 60px;
    padding: $input-padding-big;
  }

  &.xbig {
    height: 40px;
    min-width: 80px;
    padding: $input-padding-xbig;
  }

  &.xxbig {
    height: 60px;
    min-width: 120px;
    padding: $input-padding-xxbig;
  }
}

select {
  cursor: pointer;
  height: 25px;
}

div.select-box {
  display: inline-block;
  padding: 0;
  margin: 0;

  select {
    height: 23px;
    background: none;
    outline: none;
    border: none;
  }
}

a.btn,
div.btn,
label.btn {
  display: inline-block;
  box-sizing: border-box;
  border-radius: $input-border-radius;
  text-align: center;
  line-height: 15px;

  &.big {
    line-height: 25px;
    font-size: $button-font-size-big;
  }

  &.xbig {
    line-height: 30px;
    font-size: $button-font-size-xbig;
  }

  &.xxbig {
    line-height: 50px;
    font-size: $button-font-size-xxbig;
  }
}

a.btn,
button,
div.btn,
input[type="button"],
input[type="reset"],
input[type="submit"],
label.btn {
  cursor: pointer;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  padding: $button-padding;
  border-radius: $button-border-radius;
  height: 25px;
  min-width: 50px;

  transition: background 0.2s, color 0.2s;
  transition-delay: 0s;

  &.active,
  &:active {
    box-shadow: inset 0 3px 12px 3px rgba(128,128,128,0.5);
  }

  &.big {
    height: 35px;
    min-width: 60px;
    padding: $button-padding-big;
  }

  &.xbig {
    height: 40px;
    min-width: 80px;
    padding: $button-padding-xbig;
  }

  &.xxbig {
    height: 60px;
    min-width: 120px;
    padding: $button-padding-xxbig;
    font-size: 24px;
    font-weight: 400;
  }

  &.wide {
    min-width: 120px;
    padding: $button-padding-wide;
  }

  background: $button-background;
  outline: $button-outline;
  border: 1px solid $button-border;
  color: $button-color;

  &.hover,
  &:hover {
    background: darken($button-background, 10%);
  }

  &.link + &.link {
    padding-left: 0;
  }

  &.link {
    background: none;

    min-width: 0;

    color: inherit;

    &.hover,
    &:hover {
      background: none;
      text-decoration: underline;
    }

    &.active,
    &:active {
      box-shadow: none;
    }
  }

  &.none {
    background: none;

    min-width: 10px;

    padding-left: 0;
    padding-right: 0;

    color: inherit;

    &.hover,
    &:hover {
      background: none;
    }

    &.active,
    &:active {
      box-shadow: none;
    }
  }

  &.simple {
    background: $button-background-simple;
    outline: $button-outline-simple;
    border: 1px solid $button-border-simple;
    color: $button-color-simple;

    &.hover,
    &:hover {
      background: darken($button-background-simple, 10%);
    }
  }

  &.green {
    color: $button-color-simple-green;
  }

  &.simple-green {
    background: $sidestory-white;
    outline: $button-outline-simple-green;
    border: 1px solid $button-border-simple-green;
    color: $button-color-simple-green;

    &.hover,
    &:hover {
      background: $button-background-simple-green;
      color: $sidestory-white;
    }
  }

  &.simple-dark {
    background: $button-background-simple-dark;
    outline: $button-outline-simple-dark;
    border: 1px solid $button-border-simple-dark;
    color: $button-color-simple-dark;

    &.hover,
    &:hover {
      background: lighten($button-background-simple-dark, 30%);
    }
  }

  &.submit {
    background: $button-background-submit;
    outline: $button-outline-submit;
    border: 1px solid $button-border-submit;
    color: $button-color-submit;

    &.hover,
    &:hover {
      background: darken($button-background-submit, 10%);
    }
  }

  &.info {
    background: $button-background-info;
    outline: $button-outline-info;
    border: 1px solid $button-border-info;
    color: $button-color-info;

    &.hover,
    &:hover {
      background: darken($button-background-info, 10%);
    }
  }

  &.warn {
    background: $button-background-warn;
    outline: $button-outline-warn;
    border: 1px solid $button-border-warn;
    color: $button-color-warn;

    &.hover,
    &:hover {
      background: darken($button-background-warn, 10%);
    }
  }

  &.danger {
    background: $button-background-danger;
    outline: $button-outline-danger;
    border: 1px solid $button-border-danger;
    color: $button-color-danger;

    &.hover,
    &:hover {
      background: darken($button-background-danger, 10%);
    }
  }

  &.disabled,
  &:disabled {
    cursor: default;
    background: $button-background-disabled;
    outline: $button-outline-disabled;
    border: 1px solid $button-border-disabled;
    color: $button-color-disabled;

    &.hover,
    &:hover {
      background: $button-background-disabled;
    }
  }
}

form {
  max-width: 550px;
  margin: 0 auto;

  .row {
    padding: 20px;
  }

  .validation {
    display: inline-block;
    font-size: 16px;
    min-height: 20px;
    font-weight: 500;

    &.waiting {
      color: #b6b6b6;
    }

    &.valid {
      color: $sidestory-green;
    }

    &.invalid {
      color: #AB0015;
    }
  }

  .submit-message {
    display: inline-block;
    font-size: 16px;
    font-weight: 200;

    .fail,
    .success,
    .unsent {
      display: none;
    }

    &.not-sent {
      .unsent {
        display: initial;
      }
    }

    &.failed {
      color: #AB0015;

      .fail {
        display: initial;
      }
    }

    &.succeeded {
      color: $sidestory-green;

      .success {
        display: initial;
      }
    }
  }

  button + .submit-message {
    padding-left: 30px;
  }
}
