@import "./variables.styl";

.page-login {
  min-height: inherit;

  .box-login {
    position: relative;
    width: 400px;
    margin: 0 auto;

    border-radius: 5px;
    background: $sidestory-white;

    input {
      text-align: center;
    }

    h1,
    label {
      margin: 0;
    }

    .row {
      padding: 30px;
    }

    .row + .row {
      padding-top: 0;
    }

    label {
      font-weight: 300;
    }

    .terms {
      font-size: 14px;
    }
  }
}
@media only screen and (min-width: 450px) {
  .page-login {
    background: $sidestory-light-gery;

    .box-login {
      box-shadow: 0 0 16px 2px rgba(170,170,170,0.85);
    }
  }
}
@media only screen and (max-width: 350px) {
  .page-login {
    .box-login {
      .terms {
        display: inline-block;
        width: 175px;
      }
    }
  }
}
@media only screen and (max-height: 600px) {
  .page-login {
    .vertical-align {
      position: initial;
      top: initial;
      width: 100%;
      padding: 10px;
      text-align: center;
      transform: translateY(0);

      .box-login {
        position: initial;
        top: initial;
      }
    }
  }
}
