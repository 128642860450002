@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-Bold.WOFF') format('woff')
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-Book.WOFF') format('woff')
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-BookOblique.WOFF') format('woff')
  font-weight: 400;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-Heavy.WOFF') format('woff')
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-HeavyOblique.WOFF') format('woff')
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-Light.WOFF') format('woff')
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-LightOblique.WOFF') format('woff')
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura Std';
  src: url('https://dtwr9uud83x48.cloudfront.net/fonts/FuturaStd-Medium.WOFF') format('woff')
  font-weight: 500;
  font-style: normal;
}

body, .p {
    font-family: 'Futura Std';
    font-weight: 400;
    font-style: normal;
    font-size: 15pt;
}

h1, .h1 {
    font-family: 'Futura Std';
    font-weight: 700;
    font-style: normal;
    font-size: 21pt;
}

h2, .h2 {
    font-family: 'Futura Std';
    font-weight: 300;
    font-style: normal;
    font-size: 21pt;
}

h3, .h3 {
    font-family: 'Futura Std';
    font-weight: 500;
    font-style: normal;
    font-size: 15pt;
}

quote, .quote {
    font-family: 'Futura Std';
    font-weight: 300;
    font-style: oblique;
    font-size: 15pt;
}
