@import "./variables.styl";

/* Join with phone */

.join-with-phone {
  display: inline-block;
  text-align: center;
  box-sizing: content-box;
  height: 240px;
  width: 230px;

  font-size: 16px;
  font-weight: 400;

  .terms {
    display: inline-block;
    margin-top: 11px;
    width: 200px;
  }

  .success-message {
    min-height: 105px;
    line-height: normal;
    display: none;

    img {
      height: 30px;
      margin: 67.5px;
    }
  }

  &.success {
    & > :not(.success-message) {
      display: none;
    }

    .success-message {
      display: block;
    }
  }

  .error-message {
    display: none;
    color: #999999;

    margin-bottom: 15px;
  }

  &.error {
    .number {
      border-color: #a40000;
      background: #ffe4e4;
    }

    .error-message {
      display: block;
    }
  }

  &.info {
    .error-message {
      display: block;
    }
  }

  .waiting-message {
    min-height: 105px;
    line-height: normal;
    display: none;

    img {
      height: 90px;
      margin: 15px;
    }
  }

  &.waiting {
    & > :not(.waiting-message) {
      display: none;
    }

    .waiting-message {
      display: block;
    }
  }

  .number {
    margin-top: 15px;
    margin-bottom: 15px;

    input {
      text-align: center;
    }
  }
}

.header {
  .join-with-phone {
    font-size: 24px;

    .success-message {
      color: #49C4A4;
    }

    .error-message {
      color: #dddddd;
    }

    .input-text {
      input {
        color: black;
      }
    }
  }
}
/* END - Join with phone */

.modal {
  .join-with-phone-footer {
    background: transparent;

    .row:first-child {
      padding-top: 0;
    }

    .row:last-child {
      padding-bottom: 0;
    }
  }
}

form.join-with-phone-footer {
  padding: 0;
}

.join-with-phone-footer {
  background: #f2f2f2;
  text-align: center;
  padding: 30px;

  .sent-by {
    font-size: 26px;
    font-weight: 200;
  }

  label {
    font-size: 18px;
    font-weight: 400;
  }

  .terms {
    font-size: 18px;
    font-weight: 400;
  }

  input {
    text-align: center;
  }

  .row {
    padding: 10px;

    &.gap {
      padding-bottom: 0;
    }

    h1,
    h2 {
      margin: 0;
      padding: 0;
    }
  }
}
